@font-face {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(fonts/DMSans-Regular.ttf) format('truetype');
}

body {
  overflow: hidden;
}
  
.MuiTablePagination-root {
  padding-left: 0 !important;
  border: none !important;
}

.MuiTablePagination-toolbar {
  padding-left: 0 !important;
}

*::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}

*::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.dark *::-webkit-scrollbar-track {
  background-color: #252525;
}

*::-webkit-scrollbar-thumb {
  background-color: #e5e7e8;
  border-radius: 10px;
}

.dark *::-webkit-scrollbar-thumb {
  background-color: #7e7e7e;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #bdbdbd;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #868686;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

tbody {
  display: block;
  overflow: auto;
}

thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

thead {
  width: calc(100% - 1em);
}

table {
  width: 400px;
  border-collapse: separate !important;
}
